import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ResponsiveImg from '../../components/ResponsiveImg';
import { openFindNeosurf, commonHeaderColor } from '../../util';


const IndexPage = ({ data }) => (
  <>
    <SEO
      title="Buy Neosurf - Vouchers, Codes &amp; Neosurf Casinos Online"
      description="Buy Neosurf codes &amp; vouchers and pay securely on the web. Check out the list of reputable Neosurf Casinos online."
      alternateLanguagePages={[
        {
          path: '',
          language: 'en'
        },
        {
          path: '',
          language: 'fr'
        },
        {
          path: '',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.img1.childImageSharp.fluid} alt="Buy Neosurf &amp; pay securely online" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1>Neosurf kaufen und im Web bezahlen</h1>
            <p className="textColorDark">Wenn du weiterhin Online-Zahlungen tätigen möchtest, ohne sensible Daten einzugeben, probiere Neosurf! Es ist eine sichere Zahlungsmethode, die auf Gutscheinen basiert und entwickelt wurde, um Online-Zahlungen sicherer und bequemer zu machen.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Warum Neosurf?</h2>
            <p>Heute gibt es Dutzende verschiedener Zahlungsmethoden. Online-Casinos akzeptieren normalerweise zwischen wenigen und über 20 Methoden. Mit so vielen verfügbaren Möglichkeiten, warum solltest du Neosurf als Zahlungsmethode wählen?</p>
            <p>Ein Hauptgrund für die Nutzung von Neosurf ist die Sicherheit. Bei der Online-Nutzung müssen keine persönlichen oder Bankdaten angegeben werden, da Neosurf auf einem Gutscheinsystem basiert. Es wird auf vielen Gaming-, Glücksspiel- und allgemeinen Unterhaltungsseiten sowie auf anderen Websites akzeptiert. Du kannst es sogar verwenden, um dein Guthaben auf verschiedenen Prepaid-Karten und E-Wallets aufzuladen.</p>
            <p>Die Online-Glücksspielbranche verändert sich täglich. Einige der beliebtesten E-Wallets und Zahlungsmethoden sind in bestimmten Märkten nicht mehr verfügbar. Hier kommt Neosurf ins Spiel. Du kannst Neosurf-Codes in über 135.000 Einzelhandelsgeschäften weltweit kaufen.</p>
          </div>
        </div>
        <div>
          <Img fluid={data.img2.childImageSharp.fluid} alt="Neosurf codes available in 135000 stores worldwide" />
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">Neosurf - in 3 einfachen Schritten</h2>
      <p className="textAlignCenter">Einer der Gründe, warum Neosurf eine gute Zahlungsmethode ist, ist seine Benutzerfreundlichkeit. Es ist tatsächlich sehr einfach zu bedienen – es sind nur drei Schritte nötig.</p>
      <div className="grid c-3 bigGap alignItemsTop">
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">1. Einen Händler finden</h3>
            <p>Zuerst musst du einen Verkaufsort besuchen. Es gibt weltweit mehr als 135.000 dieser Standorte. Um den nächstgelegenen zu finden, klicke einfach auf diesen Link.</p>
          </div>
        </div>
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">2. PIN-Code kaufen</h3>
            <p>Dann musst du einen Neosurf-Gutschein kaufen. Die Gutscheine können in verschiedenen Beträgen gekauft werden, von mindestens 10 € bis maximal 200 €. Jeder Gutschein, den du kaufst, enthält einen 10-stelligen PIN-Code.</p>
          </div>
        </div>
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">3. Online bezahlen!</h3>
            <p>Wenn du Neosurf online verwenden möchtest, gib einfach den 10-stelligen PIN-Code ein. Jeder verbleibende Betrag kann mit demselben Code verwendet werden. Alternativ kannst du online gehen und ihn auf einen neuen Gutschein übertragen.</p>
          </div>
        </div>
      </div>
      <p className="textAlignCenter">Um das Guthaben eines Gutscheins zu überprüfen, besuche einfach die Neosurf-Website und gehe zum Abschnitt „Meine Karte“. Gib deinen 10-stelligen PIN-Code ein, um den Kontostand des Gutscheins zu sehen.</p>
    </div>
    <ContentRow backgroundColor="#eee">
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Online zahlen und spielen mit Neosurf</h2>
            <p className="textColorDark">Möchtest du keine Debit-/Kreditkarten für Online-Zahlungen verwenden? Oder hast du vielleicht gar keine? Du möchtest möglicherweise keine sensiblen Bankdaten angeben, da dir der Datenschutz besonders wichtig ist?</p>
            <p>Neosurf könnte die perfekte Lösung für dich sein! Kaufe einen Neosurf-Gutschein in bar und bezahle und spiele sicher online. So einfach ist das! Sieh dir ein kurzes Video an, das erklärt, wie das Neosurf-Gutscheinsystem funktioniert.</p>
          </div>
        </div>
        <div>
          <div className="videoWrapper">
            <iframe
              title="neosurfVideo"
              src="https://www.youtube.com/embed/ZZKglPJVhFE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow
      backgroundColor="rgba(255,0,155, 0.8)"
      Img={
        <ResponsiveImg
          aspectRatio={data.img3.childImageSharp.fixed.aspectRatio}
          src={data.img3.childImageSharp.fixed.src}
          alt=""
        />
      }
      padV
    >
      <div className="contentBox textAlignCenter">
        <h2 className="smaller textColorComplementary">Finde einen Neosurf-Händler in deiner Nähe</h2>
        <p className="textColorLight">Kunden mögen Neosurf-Bargeldgutscheine, da sie an Tausenden von bequemen Standorten weltweit erhältlich sind. Heute findest du Neosurf-Bargeldgutscheine in Convenience Stores, Supermärkten, Tankstellen, Kiosken, Tabakläden, Internetcafés und mehr.</p>
        <button className="button big" onClick={openFindNeosurf}>Where to find Neosurf?</button>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">Neosurf-Casinos und Partner</h2>
      <p className="textAlignCenter">Eine der vielen Einsatzmöglichkeiten von Neosurf ist die Finanzierung von Online-Glücksspielaktivitäten. Insbesondere kannst du es verwenden, um Geld auf Online-Casino-Konten einzuzahlen. Eine wachsende Anzahl von Casinos akzeptiert diese Zahlungsmethode aufgrund ihrer Sicherheit und Zuverlässigkeit. Sieh dir empfohlene Neosurf-Casinos und Partner an.</p>
      <div className="grid c-3 bigGap" style={{ alignItems: 'flex-start' }}>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
            <h3>LuckyStar</h3>
            <p className="zeroMarginT">Unter dem Motto „Glückliche Spieler sind die beste Werbung“ ist das LuckyStar Casino ein wahrer Stern in der Online-Glücksspielbranche. Mit über 1000 Casino-Spielen, einer breiten Auswahl an Zahlungsmethoden, einschließlich Neosurf, und preisgekröntem Kundensupport wird dir hier ein erstklassiges Spielerlebnis geboten. Melde dich heute an und erhalte einen 100% Willkommensbonus.</p>
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" className="button">Jetzt besuchen</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
            <h3>BetBTC Casino</h3>
            <p className="zeroMarginT">BetBTC ist ein „hybrides“ Fiat- & Krypto-Casino, was bedeutet, dass dein Konto in Fiat-Währung geführt wird, du aber sowohl mit Fiat-Transfermethoden als auch mit BITCOIN einzahlen und abheben kannst, das automatisch in den Fiat-Wert umgerechnet wird! Mit modernem Design, schneller Plattform, großer Spielauswahl und den besten Boni – BetBTC Casino bietet wirklich alles! Und ja, es akzeptiert Spieler von überall!</p>
            <a href="https://www.betbtc.io/sign-up/a48acf12" className="button">Jetzt besuchen</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">
              <Img fluid={data.auslots.childImageSharp.fluid} alt="" />
            </a>
            <h3>AUSlots Casino</h3>
            <p className="zeroMarginT">Suchst du nach großartigen Slot-Spielen? Einzigartige und moderne Online-Slotmaschinen? AUSlots könnte der perfekte Ort für dich sein! Mit über 1000 Casino-Spielen, einschließlich der neuesten Slots und Live-Casino-Spiele, bietet AUSlots großartige Unterhaltung und ein unvergleichliches Spielerlebnis. Melde dich heute an und sichere dir 150 $/€ als Willkommensgeschenk!</p>
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" className="button">Jetzt besuchen</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">
              <Img fluid={data.emu.childImageSharp.fluid} alt="" />
            </a>
            <h3>Emu Casino</h3>
            <p className="zeroMarginT">Emu Casino ist eines der bestbewerteten australischen Online-Casinos, die die Neosurf-Einzahlungsmethode akzeptieren. Es bietet über tausend Spiele von den besten Spielanbietern der Welt, darunter mehr als 900 Pokies!</p>
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" className="button">Jetzt besuchen</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001">
              <Img fluid={data.slotsBay.childImageSharp.fluid} alt="" />
            </a>
            <h3>777 Slots bay</h3>
            <p className="zeroMarginT">777SlotsBay, ein neues Online-Casino von einem erfahrenen Team, bietet dir die neuesten und besten Slots und Casinospiele, die auf allen Geräten spielbar sind. Und es akzeptiert Neosurf!</p>
            <a href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001" className="button">Jetzt besuchen</a>
          </div>
        </div>
      </div>
      <h2 className="smaller textAlignCenter textUppercase zeroMarginB">FAQ</h2>
      <div className="grid c-2 bigGap">
        <div>
          <Img className="roundedBorder" fluid={data.img4.childImageSharp.fluid} alt="Withdrawals using Neosurf" />
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">Kann ich mit Neosurf in Online-Casinos auszahlen?</h3>
            <p>Leider ist es nicht möglich, mit dieser Zahlungsmethode Abhebungen vorzunehmen. Casinos erlauben dir jedoch, eine andere Zahlungsmethode für die Auszahlung deiner Gewinne zu verwenden.</p>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">Wird für die Nutzung dieser Zahlungsmethode eine Gebühr erhoben?</h3>
            <p>Eine Gebühr fällt nur an, wenn du Gutscheine in einer Währung kaufst und Zahlungen in einer anderen Währung vornimmst. In diesem Fall wird eine Umrechnungsgebühr von 2 % (3 % für Transaktionen mit dem polnischen Zloty) abgezogen. Außerdem wird eine Inaktivitätsgebühr von 2 € pro Monat auf Gutschein-Konten erhoben, die mindestens sechs Monate oder ein Jahr nach dem Kauf inaktiv waren.</p>
          </div>
        </div>
        <div>
          <Img className="roundedBorder" fluid={data.img5.childImageSharp.fluid} alt="Frequently asked questions" />
        </div>
      </div>
    </div>
  </>
)

export default IndexPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    img1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/mac1.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 1080,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/model-card.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "bg2.jpg"}) {
      childImageSharp {
        fixed(width: 524) {
          aspectRatio
          src
        }
      }
    }
    img4: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/home_4.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/home_6.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/ls.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/betbtc.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auslots: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/AUSlots-Casino-home.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/emucasino-logo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slotsBay: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/777-slots-bay-logo-getneosurf.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
